.cartitems{
    margin: 50px 170px;
}
.cartitems hr{
    height: 3px;
    background: #e2e2e2;
    border: 0;
}
.category-card hr{
    height: 3px;
    margin-top: 2px;
    width: 160px;
    background: #e2e2e2;
    border: 0;
}
.cartitems-format-main{
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 75px;
    padding: 20px 0px;
    color: #454545;
    font-size: 18px;
    font-weight: 600;
}
.order-detail{
    border: 1px solid #eaeaea;
    border-radius: 10px;
    gap: 10px;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
}
.caritems-format{
    font-size: 16px;
    font-weight: 500;
}
.carticon-product-icon{
    height: 62px;
}
.cartitems-remove-icon{
    width: 15px;
    margin: 0px 40px;
    cursor: pointer;
 }
.cartitems-quantity{
    width: 64px;
    height: 50px;
    border: 2px solid #ebebeb;
    background: #fff;
}

.checkout-down{
    display: flex;
    margin: -20px 0px;
}
.cartitems-total{
    margin-bottom: 20px;
    padding: 0px 10px 0px 10px;
}
.category-container{
    margin-bottom: 20px;
    padding: 0px 10px 0px 10px;
}
.address-container{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 200px;
    gap: 4px;
}
.address-container h1{
    font-size: 20px;
    font-weight: 700;
    color: #ED2126;
}
.cartitems-total h1{
    font-size: 20px;
    font-weight: 700;
    color: #ED2126;
}
.cartitems-total-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin-top: 10px;
}
.category-item{
    align-items: center;
    padding: 10px 0;
    margin-top: 20px;
    font-size: 17px;
    color: #282c34;
}
.cartitems-total button{
    margin-top: 20px;
    padding: 10px;
    width: 190px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background: #ED2126;
    margin-bottom: 40px;
    border: 1px solid #ED2126;
    outline: none;
    cursor: pointer;
    border-radius: 30px;
}
.cartitems-total button:hover{
    border: 1px solid #ED2126;
    background: #fff;
    color: #ED2126;
}
.address-container button{
    margin-top: 20px;
    padding: 10px;
    width: 190px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background: #ED2126;
    margin-bottom: 40px;
    border: 1px solid #ED2126;
    outline: none;
    cursor: pointer;
    border-radius: 30px;
}
.address-container button:hover{
    border: 1px solid #ED2126;
    background: #fff;
    color: #ED2126;
}
.cartitems-promocode{
    flex: 1;
    font-size: 16px;
    font-weight: 500;
}
.cartitems-promocode p{
    color: #555;
}
.cartitems-promobox{
    width: 504px;
    margin-top: 15px;
    padding-left: 20px;
    height: 58px;
    background: #eaeaea;
    display: flex;
    align-items: center;
    gap: 10px;
}
.cartitems-promobox input{
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    width: 330px;
    height: 50px;
}
.applied-coupon {
    margin-top: 10px;
    font-size: 16px;
    color: green;
}

.remove-coupon {
    font-size: 18px;
}
.cartitems-promobox button{
    width: 170px;
    height: 58px;
    font-size: 16px;
    background: black;
    color: #fff;
    cursor: pointer;
}

/* Responsive styles */
@media (max-width: 1200px) {
    .cartitems {
        margin: 80px 100px;
    }
    .cartitems-format-main {
        grid-template-columns: 1fr 2fr 1fr 1fr;
        gap: 40px;
    }
    .cartitems-total {
        margin-right: 100px;
    }
    .category-container {
        margin-right: 100px;
    }
    .address-container {
        margin-right: 100px;
    }
    .cartitems-promobox {
        width: 400px;
    }
}

@media (max-width: 768px) {
    .cartitems {
        margin: 50px 50px;
    }
    .cartitems-format-main {
        grid-template-columns: 1fr 2fr 1fr;
        gap: 20px;
    }
    .cartitems-total {
        margin-right: 50px;
    }
    .category-container {
        margin-right: 50px;
    }
    .address-container {
        margin-right: 50px;
    }
    .cartitems-promobox {
        width: 300px;
    }
}

@media (max-width: 480px) {
    .cartitems {
        margin: 30px 20px;
    }
    .cartitems-format-main {
        grid-template-columns: 1fr 1.5fr;
        gap: 10px;
    }
    .cartitems-total {
        margin-right: 20px;
    }
    .category-container {
        margin-right: 20px;
    }
    .cartitems-total button {
        width: 100%;
    }
    .address-container {
        margin-right: 20px;
    }
    .address-container button {
        width: 100%;
    }
    .cartitems-promobox {
        width: 100%;
        padding-left: 10px;
    }
    .cartitems-promobox input {
        width: 60%;
    }
    .cartitems-promobox button {
        width: 40%;
    }
}

/***Checkout****/
.checkout-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #000;
}
.address-option{
    margin: 10px;
}
.saved-addresses {
    width: 99%;
    padding: 10px;
    background-color: #f6f9fc;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.form-container {
    width: 100%;
}
.form-container2 {
    width: 90%;
}
.form-container h1 {
    font-size: 20px;
    font-weight: 700;
    color: #ED2126;
}

.address-form, .gst-form, .billing-address-form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.address-form input, .gst-form input, .billing-address-form input {
    width: 48%;
    margin: 5px 1%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}
.input-form{
    width: 48%;
    margin: 5px 1%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}
.input-form-edit{
    width: fit-content;
    margin: 5px 1%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}
.review-input-form{
    width: fit-content;
    margin: 5px 1%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.checkbox-container {
    margin: 10px 0;
}

.checkbox-container label {
    font-size: 14px;
}

.gst-form, .billing-address-form {
    margin-top: 20px;
}

.gst-form input, .billing-address-form input {
    width: 48%;
}
.label{

}
input[type="checkbox"] {
    margin-right: 5px;
    width: 15px;
    margin-top: -15px;
}
input[type="radio"] {
    margin-right: 5px;
    width: 15px;
    margin-top: -15px;
}

/* For tablets and below */
@media (max-width: 768px) {
    .checkout-container {
        flex-direction: column;
        align-items: center;
    }

    .saved-addresses {
        width: 100%;
        margin-bottom: 20px;
    }

    .address-form input, .gst-form input, .billing-address-form input {
        width: 100%;
        margin: 5px 0;
    }

    .form-container {
        width: 100%;
    }
    .cartitems-down{
        flex-direction: column;
    }
    .cart-total-card{
        flex-direction: column;
    }
    .checkout-down{
        flex-direction: column;
    }
}

/* For mobile devices */
@media (max-width: 480px) {
    .cartitems-down{
        flex-direction: column;
    }
    .cart-total-card{
        flex-direction: column;
    }
    .checkout-down{
        flex-direction: column;
    }
    .checkout-container {
        padding: 10px;
    }

    .form-container h1 {
        font-size: 18px;
    }

    .checkbox-container label {
        font-size: 12px;
    }

    input[type="checkbox"], input[type="radio"] {
        width: 12px;
        margin-top: -16px;
    }
}

.checkout-box{
    display: flex;
    flex-direction: column;
}

.cart-total-card {
    width: 150%;
    max-width: 350px;
    padding: 20px;
    background-color: #f6f9fc;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: auto;
}
.side-panel {
    margin: 135px 0px 0px 20px;
    width: 150%;
    max-width: 300px;
    /*max-height: 1500px;*/
    padding: 20px;
    background-color: #f6f9fc;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}
.category-card {
     width: 150%;
     max-width: 350px;
     /*max-height: 1500px;*/
     padding: 20px;
     background-color: #fff;
     border: 1px solid #ddd;
     border-radius: 8px;
     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
     margin-left: auto;
     margin-top: 12px;
 }

/****Slider Price***/
.wrapper {
    color: #282c34;
}

header h2 {
    font-size: 24px;
    font-weight: 600;
}
header p {
    margin-top: 5px;
    font-size: 16px;
}
.price-input {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 30px 0 35px;
}
.price-input .field {
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
}
.field input {
    width: 100%;
    height: 100%;
    outline: none;
    margin-left: 12px;
    text-align: left;
    border-radius: 5px;
    -moz-appearance: textfield;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.price-input .separator {
    width: 130px;
    display: flex;
    font-size: 19px;
    align-items: center;
    justify-content: center;
}
.slider {
    height: 5px;
    position: relative;
    background: #ddd;
    border-radius: 5px;
}
.slider .progress {
    height: 100%;
    left: 25%;
    right: 25%;
    position: absolute;
    border-radius: 5px;
    background: #f42c37;
}
.range-input {
    position: relative;
}
.range-input input {
    position: absolute;
    width: 100%;
    height: 5px;
    top: -5px;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
input[type="range"]::-webkit-slider-thumb {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background: #f42c37;
    pointer-events: auto;
    -webkit-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}
input[type="range"]::-moz-range-thumb {
    height: 17px;
    width: 17px;
    border: none;
    border-radius: 50%;
    background: #f42c37;
    pointer-events: auto;
    -moz-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

.review-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.upload-area {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin: 10px 0;
}
.upload-placeholder {
    color: #888;
}
.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.75) !important;

}
input:disabled {
    background-color: #eaeaea;
    cursor: not-allowed;
}
