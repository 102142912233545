.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
header {
  z-index: 1000;
  position: relative;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container{
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}
.sticky-icon  {
  z-index:1;
  position:fixed;
  top:15%;
  right:0%;
  width:220px;
  display:flex;
  flex-direction:column;
  pointer-events: none;
}
.sticky-icon a  {
  transform:translate(160px,0px);
  border-radius:50px 0px 0px 50px;
  text-align:left;
  margin:2px;
  text-decoration:none;
  text-transform:uppercase;
  padding:10px;
  font-size:22px;
  font-family:'Oswald', sans-serif;
  transition:all 0.8s;
pointer-events: all;
}
.sticky-icon a:hover  {
  color:#FFF;
  transform:translate(0px,0px);}
.sticky-icon a:hover i  {
  transform:rotate(360deg);}
/*.search_icon a:hover i  {
	transform:rotate(360deg);}*/
.Facebook  {
  background-color:#2C80D3;
  color:#FFF;}

.Youtube  {
  background-color:#fa0910;
  color:#FFF;}

.Linkedin  {
  background-color:#0077B5 ;
  color:#FFF;}

.Instagram  {
  background-color:#D62976;
  color:#FFF;}

.Whatsapp  {
  background-color:#25D366;
  color:#FFF;}
.sticky-icon a i {
  background-color:#FFF;
  height:40px;
  width:40px;
  color:#000;
  text-align:center;
  line-height:40px;
  border-radius:50%;
  margin-right:20px;
  transition:all 0.5s;}
.sticky-icon a i.fa-facebook-f  {
  background-color:#FFF;
  color:#2C80D3;}

.sticky-icon a i.fa-whatsapp  {
  background-color:#FFF;
  color:#075E54;}

.sticky-icon a i.fa-instagram  {
  background-color:#FFF;
  color:#D62976;}

.sticky-icon a i.fa-youtube  {
  background-color:#FFF;
  color:#fa0910;}

.sticky-icon a i.fa-linkedin  {
  background-color:#FFF;
  color:#0077B5 ;}
.fas fa-shopping-cart  {
  background-color:#FFF;}
#myBtn {
  height:50px;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  text-align:center;
  padding:10px;
  text-align:center;
  line-height:40px;
  border: none;
  outline: none;
  background-color: #1e88e5;
  color: white;
  cursor: pointer;
  border-radius: 50%;
}
.fa-arrow-circle-up  {
  font-size:30px;}

#myBtn:hover {
  background-color: #555;
}

.d_flex{
  display: flex;
  justify-content: space-between;
}

.c_flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.f_flex{
  display: flex;
}

a {
  text-decoration: none;
  color: #000;
}
li{
  list-style: none;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize{
  text-transform: capitalize;
}
.RText{
  text-align: right;
}

input,
button{
  border: none;
  outline: none;
  background-color: none;
}

.row{
  width: 50%;
}

.icon-circle{
  width: 20px;
  height: 20px;
  line-height: 50px;
  margin-left: 20px;
  text-align: center;
  border-radius: 50%;
}

.boxShadow{
  box-shadow: rgb(43 52 69 / 100%) 0 4px 16px;
}

/*------Category----------*/
.contentWidth{
  width: 77%;
}
.category{
  width: 23%;
  box-shadow: rgb(43 52 69 / 10%) 0 4px 16px;
  height: 295px;
  padding: 15px 0;
  border-radius: 5px;
  margin-top: 5px;
}

.category img{
  width: 30px;
  height: 30px;
  margin-top: 10px;
  object-fit: contain;
}

.category span{
  margin: 10px 20px;
  text-transform: capitalize;
}

.category .box{
  transition: 0.5s;
  padding: 0 20px;
}

.category .box:hover{
  background-color: #ffe1ef;
  cursor: pointer;
}

/*--------End Category--------*/

.btn-primary{
  background-color: #e94560;
  padding: 13px 40px;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
}

.top{
  margin-top: 80px;
}
.mtop{
  margin-top: 40px;
}

.heading .icon{
  color: #e94560;
  margin: 10px;
}

.flash-background{
  background-color: #f6f9fc;
  padding: 40px;
}

/*----------Product----------*/
.product{
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0 1px 3px;
  margin: 10px;
}

.product h3{
  font-weight: 500;
  font-size: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.product .rate .icon{
  font-size: 15px;
  color: #ffcd4e;
  margin: 5px 5px 5px 0;
}

.product .price{
  display: flex;
  justify-content: space-between;
  color: #000;
}

.product button{
  background: none;
  color: #f42c37;
  font-size: 20px;
  border: 1px solid rgb(3 0 71 / 9%);
  width: 35px;
  height: 35px;
  border-radius: 5px;
  transition: 0.2s;
  margin-top: 20px;
}

.product button:hover{
  background-color: #f42c37;
  cursor: pointer;
  color: #fff;
}

.product-like{
  position: absolute;
  top: 0px;
  right: 0;
  margin: 10px;
  opacity: 0;
  transition: 0.5s;
}
.product-like .icon{
  font-size: 20px;
  margin: 10px 3px;
}
.product:hover .product-like {
  opacity: 1;
}
/*----------End Product----------*/
/*----- control btn -------*/
.control-btn .next,
.control-btn .prev {
  position: absolute;
  top: 40%;
  width: 50px;
  height: 50px;
  line-height: 60px;
  background: #0f3460;
  border-radius: 50%;
  transform: 0.5s;
  z-index: 5;
}
.control-btn .prev {
  left: -20px;
}
.control-btn .next {
  right: -20px;
}
.control-btn .prev .icon,
.control-btn .next .icon {
  font-size: 20px;
  transition: 0.5s;
  color: #fff;
}
.control-btn .prev:hover,
.control-btn .next:hover {
  background: #e94560;
  color: white;
  cursor: pointer;
}
/*----- control btn -------*/
/*----- heading -------*/
.heading .heading-left .icon{
  font-size: 30px;
}
.heading .heading-left h2 {
  font-size: 25px;
  margin: 5px;
}

.heading .heading-right,
.heading .heading-right .icon {
  text-align: right;
  color: grey;
  margin: 0px;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .product h3 {
    font-size: 1.1rem;
  }
}

@media (max-width: 992px) {
  .product h3 {
    font-size: 1rem;
  }

  .product-details .text-xl {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .product h3 {
    font-size: 0.9rem;
  }

  .product-details .text-xl {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .product h3 {
    font-size: 0.8rem;
  }

  .product-details .text-xl {
    font-size: 0.8rem;
  }

  .product .img {
    padding: 10px;
  }
}


.product-page {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.filter-sidebar {
  flex: 1 0 30px;
  padding: 20px;
  border-radius: 8px;
}

.product-list {
  flex: 3 1 600px;
  flex-wrap: wrap;
  gap: 20px;
}

.product-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 200px;
  text-align: center;
}

.product-card img {
  max-width: 100%;
  border-radius: 8px;
}


/****Order Confirmation****/
.order-confirmation-container {
  margin: 0 auto;
  padding: 20px;
}

.order-confirmation-message {
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
}

.order-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-products {
  flex: 2;
  padding-right: 20px;
}

.order-products ul {
  list-style-type: none;
  padding: 0;
}

.product-item {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.order-subtotal {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.order-summary {
  flex: 1;
  padding-left: 20px;
  border-left: 1px solid #ddd;
}

.shipping-address, .payment-method {
  margin-bottom: 20px;
}

.shipping-address p, .payment-method p {
  margin: 5px 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .order-details {
    flex-direction: column;
  }

  .order-products {
    padding-right: 0;
  }

  .order-summary {
    padding-left: 0;
    border-left: none;
    margin-top: 20px;
  }
}

.banner{

}


