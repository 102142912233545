/*a{*/
/*    color: unset;*/
/*    text-decoration: none;*/
/*}*/
/*.title{*/
/*    font-size: xx-large;*/
/*    padding: 20px 0;*/
/*}*/
/*.listProduct .item img{*/
/*    width: 90%;*/
/*    filter: drop-shadow(0 50px 20px #0009);*/
/*}*/
/*.listProduct{*/
/*    display: grid;*/
/*    grid-template-columns: repeat(4, 1fr);*/
/*    gap: 20px;*/
/*}*/
/*.listProduct .item{*/
/*    background-color: #EEEEE6;*/
/*    padding: 20px;*/
/*    border-radius: 20px;*/
/*}*/
/*.listProduct .item h2{*/
/*    font-weight: 500;*/
/*    font-size: large;*/
/*}*/
/*.listProduct .item .price{*/
/*    letter-spacing: 7px;*/
/*    font-size: small;*/
/*}*/
/*!* detail page *!*/

/*.detail{*/
/*    display: grid;*/
/*    grid-template-columns: repeat(2, 1fr);*/
/*    gap: 50px;*/
/*    text-align: left;*/
/*}*/
/*.
/*}*/
/*.detail .image{*/
/*    position: relative;*/
/*}*/
/*.detail .image::before{*/
/*    position: absolute;*/
/*    width: 300px;*/
/*    height: 300px;*/
/*    content: '';*/
/*    background-color: #94817733;*/
/*    z-index: -1;*/
/*    border-radius: 190px 100px 170px 180px;*/
/*    left: calc(50% - 150px);*/
/*    top: 50px;*/

/*}*/
/*.detail .name{*/
/*    font-size: xxx-large;*/
/*    padding: 40px 0 0 0;*/
/*    margin: 0 0 10px 0;*/
/*}*/
/*.detail .price{*/
/*    font-weight: bold;*/
/*    font-size: x-large;*/
/*    letter-spacing: 7px;*/
/*    margin-bottom: 20px;*/
/*}*/
/*.detail .buttons{*/
/*    display: flex;*/
/*    gap: 20px;*/
/*    margin-bottom: 20px;*/
/*}*/
/*.detail .buttons button{*/
/*    background-color: #eee;*/
/*    border: none;*/
/*    padding: 15px 20px;*/
/*    border-radius: 20px;*/
/*    font-family: Poppins;*/
/*    font-size: large;*/
/*}*/
/*.detail .buttons svg{*/
/*    width: 15px;*/
/*}*/
/*.detail .buttons span{*/
/*    background-color: #555454;*/
/*    width: 30px;*/
/*    height: 30px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    border-radius: 50%;*/
/*    margin-left: 20px;*/
/*}*/
/*.detail .buttons button:nth-child(2){*/
/*    background-color: #2F2F2F;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    color: #eee;*/
/*    box-shadow: 0 10px 20px #2F2F2F77;*/
/*}*/
/*.detail .description{*/
/*    font-weight: 300;*/
/*}*/
/*!* // ipad *!*/
/*@media only screen and (max-width: 992px) {*/
/*    .listProduct{*/
/*        grid-template-columns: repeat(3, 1fr);*/
/*    }*/
/*    .detail{*/
/*        grid-template-columns:  40% 1fr;*/
/*    }*/
/*}*/


/*!* mobile *!*/
/*@media only screen and (max-width: 768px) {*/
/*    .listProduct{*/
/*        grid-template-columns: repeat(2, 1fr);*/
/*    }*/
/*    .detail{*/
/*        text-align: center;*/
/*        grid-template-columns: 1fr;*/
/*    }*/
/*    .detail .image img{*/
/*        width: unset;*/
/*        height: 40vh;*/
/*    }*/
/*    .detail .name{*/
/*        font-size: x-large;*/
/*        margin: 0;*/

/*    }*/
/*    .detail .buttons button{*/
/*        font-size: small;*/
/*    }*/
/*    .detail .buttons{*/
/*        justify-content: center;*/
/*    }*/
/*}*/

.productdetails-img-list{
    display: flex;
    flex-direction: column;
    gap: 14px;
    overflow-x: hidden;
    max-height: 460px;
}
.review-img-list{
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin: 15px;
}
.arrow-up-down{
    width: 22px;
    margin-left: 35px;
    opacity: 40%;
}
.productdetails-img-list::-webkit-scrollbar {
    width: 0px;
}
.productdetails-img-list::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}
.productdetails-img-list.thick-scrollbar::-webkit-scrollbar {
    width: 0px;
}

.productdetails-img-list.thick-scrollbar::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
}

.product-details{
    display: flex;
    margin: 50px 170px;
}

.product-details-left{
    display: flex;
    gap: 17px;
}

.review-img-list img{
    height: 80px;
    max-width: 80px;
    background: #f6f9fc;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 30%) 0 1px 3px;

}
.productdetails-img-list img{
    height: 80px;
    max-width: 80px;
    background: #f6f9fc;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 30%) 0 1px 3px;

}
.productdetails-main-img{
    max-width: 500px;
    height: 500px;
    background: #f6f9fc;
    border-radius: 8px;
    box-shadow: rgb(3 0 71 / 30%) 0 1px 3px;
    transition: transform 0.2s ease;
}
.productdetails-img {
    position: relative;
    overflow: hidden;

}
.productdetails-img.zoom-active .productdetails-main-img {
    transform: scale(2.5);
}

.productdetails-img.zoom-active {
    cursor: crosshair;
}

.productdetails-img.zoom-active:hover .productdetails-main-img {
    transition: none;
}
.product-details-right{
    margin: 0px 70px;
    display: flex;
    flex-direction: column;
}

.product-details-right h1{
    color: #3d3d3d;
    font-size: 40px;
    font-weight: 700;
}

.productdetails-right-star{
    display: flex;
    align-items: center;
    margin-top: 13px;
    margin-bottom: 10px;
    gap: 5px;
    color: #f8c906;
    font-size: 18px;
}

.productdetails-right-prices{
    display: flex;
    margin: 20px 0px;
    gap: 30px;
    font-size: 24px;
    font-weight: 700;
}
.productdetails-right-price-old{
    color: #818181;
    text-decoration: line-through;
}
.productdetails-right-price-new{
    color: #ED2126;
}
.productdetails-right-quantity h1{
    margin-top: 20px;
    color: #656565;
    font-size: 20px;
    font-weight: 600;
}

.quantity{
    border: 1px solid #656565;
    border-radius: 5px;
    padding: 10px;
    width: 80px;
    margin-top: 10px;
}

.button{
    margin-top: 40px;
    padding: 10px;
    width: 120px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background: #ED2126;
    margin-bottom: 40px;
    border: 1px solid #ED2126;
    outline: none;
    cursor: pointer;
    border-radius: 30px;
}
.button:hover{
    color: #ED2126;
    background: #FFf;
    transition: 0.4s;
}

.productdisplay-right-category{
    margin-top: 10px;
}
.productdisplay-right-category span{
    font-weight: 600;
}

.quantity-controls {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.quantity-btn {
    background-color: #f0f0f0;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.quantity-btn:hover{
    color: #ED2126;
}
.quantity {
    margin: 0 10px;
    font-size: 16px;
    width: 30px;
    text-align: center;
    border: none;
}
.quantity-btn:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.productdetails-main-img {
    transition: transform 0.3s ease-in-out;
}


.review-img-list img {
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}
.review-img-list img:hover {
    transform: scale(1.2);
    opacity: 1;
}
.productdetails-img-list img {
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}
.productdetails-img-list img:hover {
    transform: scale(1.2);
    opacity: 1;
}

.productdetails-img-list img.active {
    border: 2px solid #000;
    opacity: 1;
}

.productdetails-img-list img:not(.active) {
    opacity: 0.6;
}
.display-flex{
    display: flex;
    justify-content: space-between;
}

/* Responsive styles */
@media (max-width: 1200px) {
    .product-details {
        margin: 0px 50px;
        flex-direction: column;
        align-items: center;
    }
    .product-details-right {
        margin: 30px 0;
    }
    .productdetails-main-img {
        width: 350px;
        height: 350px;
    }
    .productdetails-img-list img {
        height: 75px;
        width: 75px;
    }
}

@media (max-width: 768px) {
    .product-details {
        margin: 10px 20px;
        flex-direction: column;
        align-items: center;
    }
    .productdetails-main-img {
        width: 300px;
        height: 300px;
    }
    .productdetails-img-list img {
        height: 60px;
        width: 60px;
    }
    .product-details-right h1 {
        font-size: 35px;
    }
    .productdetails-right-prices {
        flex-direction: column;
        gap: 10px;
    }
    .product-details-right button {
        margin-top: 10px;
    }
    .arrow-up-down {
        width: 20px;
        margin-left: 15px;
        opacity: 40%;
    }
    .productdetails-img-list {
        max-height: 300px;
    }

}

@media (max-width: 480px) {
    .product-details {
        margin: 15px 10px;
        flex-direction: column;
        align-items: center;
    }
    .productdetails-main-img {
        width: 250px;
        height: 250px;
    }
    .productdetails-img-list img {
        height: 50px;
        width: 50px;
    }
    .product-details-right h1 {
        font-size: 22px;
    }
    .productdetails-right-prices {
        font-size: 20px;
    }
    .productdetails-right-star {
        font-size: 16px;
    }
    .quantity {
        width: 60px;
    }
    .arrow-up-down {
        width: 20px;
        margin-left: 15px;
        opacity: 40%;
    }
    .productdetails-img-list {
        max-height: 200px;
    }
}

/* Hide filter by default */
.product-filter {
    display: none;
}

.product-filter.visible {
    display: block;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    background: white;
    padding: 1rem;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
}
.curriculum-index {
    display: none;
}

.curriculum-index.visible {
    display: block;
    position: fixed;
    top: 130px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    background: white;
    padding: 1rem;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
}

@media (min-width: 1024px) {
    .product-filter {
        display: block;
        position: static;
        transform: none;
        width: auto;
        max-height: none;
        overflow-y: visible;
        padding: 0;
        box-shadow: none;
    }
    .curriculum-index {
        display: block;
        position: static;
        transform: none;
        width: auto;
        max-height: none;
        overflow-y: visible;
        padding: 0;
        box-shadow: none;
    }
}

.filter-button {
    background: #f42c37;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    top: 1rem;
    left: 1rem;
    z-index: 1100;
    width: auto;
}

.close-button {
    background: #f42c37;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    border-radius: 4px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

@media (max-width: 1024px) {
    .filter-button {
        display: block;
    }
}

@media (min-width: 1024px) {
    .filter-button {
        display: none;
    }

}

@media (max-width: 1024px) and (min-width: 768px) {
    .product-filter.visible {
        width: fit-content;
        padding: 1.5rem;
    }
    .curriculum-index.visible {
        width: fit-content;
        padding: 1.5rem;
    }
}

@media (max-width: 768px) {
    .product-filter.visible {
        width: fit-content;
        padding: 1.5rem;
    }
    .curriculum-index.visible {
        padding: 1.5rem;
    }
}
/* For tablet screen sizes (768px to 1024px) */
@media (max-width: 1024px) and (min-width: 768px) {
    .curriculum-index.visible {
        width: 480px;
        padding: 1.5rem;     /* Ensure sufficient padding */
        /* Additional styles specific to tablets can be added here */
    }
}

/* For mobile screen sizes (up to 768px) */
@media (max-width: 768px) {
    .curriculum-index.visible {
        padding: 1.5rem;     /* Maintain padding */
    }
}

@media (min-width: 1024px) {

    .curriculum-index.visible {
        width: auto;
        padding: 2rem; /* Increased padding for larger displays */
    }
}


.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #ccc #f7f7f7;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 100px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: #f7f7f7;
}

.curriculum-card {
    flex-basis: 33.33333%;
    padding: 15px;
}
.inner-card  {
    background-color: #fff;
    padding: 15px;
    box-shadow: 0 1px 2px rgba(0,0,0,.1)
}
.img-wrapper {
    width: 100%;
    height: 250px;
    margin-bottom: 10px;
}
.img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.content {
    margin-bottom: 20px;
}
.content h1 {
    font-weight: 900;
    font-size: 16px;
    margin-bottom: 10px;
    color: #444;
}
.content p {
    font-size: 14px;
    line-height: 1.5;
    color: #555;
}

/* Effect */
.effect .light-box {
    opacity: 1;
    visibility: visible;
}
.effect .light-box .box {
    transform: scale(1);
}


