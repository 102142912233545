/* Container styling */
.blog-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    max-width: 1200px;
    margin: auto;
    width: 100%;
}

/* Image container */
.image-container {
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    max-width: 100%;
}

.image-container img {
    width: 100%;
    height: auto;
    border-radius: 16px;
    transition: transform 0.3s ease-in-out;
}

.image-container:hover img {
    transform: scale(1.05);
}

/* Content Section */
.content-section {
    margin-top: 16px;
    padding: 0 16px;
    width: 100%;
}

.content-section p.title {
    font-size: 1.25rem;
    font-weight: 700;
    color: #333;
    text-align: center;
    margin-bottom: 8px;
}

.content-section p.text {
    font-size: 0.875rem;
    color: #555;
    line-height: 1.5;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .content-section p.title {
        font-size: 1.125rem; /* Slightly smaller title on tablets */
    }

    .content-section p.text {
        font-size: 0.875rem;
        line-height: 1.4;
    }
}

@media (max-width: 480px) {
    .blog-detail-container {
        padding: 8px; /* Reduced padding on small screens */
    }

    .content-section p.title {
        font-size: 1rem; /* Smaller title for mobile */
    }

    .content-section p.text {
        font-size: 0.75rem; /* Smaller body text for mobile */
    }
}
